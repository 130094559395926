import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import LayoutCover from "../components/layout-cover"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import './index.css'

const IndexPage = () => (
  <LayoutCover>
    <Seo title="Home" />
    <div className="link-fb-wrapper">
        <div className="h-group">
          <h1 className="h-main">Javier Suzuki</h1>
          <h2 className="h-tagline">Designer &amp; Front-end Developer</h2>
        </div>
        <Link to="/portfolio-ai-driven-wine-recommender" className="link-fb">View Portfolio</Link>
        <a href="https://www.linkedin.com/in/javiersuzuki" target="_blank" rel="noopener noreferrer" className="link-fb ghost">LinkedIn</a>
    </div>
  </LayoutCover>
)

export default IndexPage

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container, Row, Col} from 'reactstrap';

const Footer = ({ siteTitle }) => (
  
        <footer className="footer">
          <div 
            className="footer-content"
            style={{
              color: `#fff`
            }}
          >
            <p>© {new Date().getFullYear()} Javier Suzuki. Built with React (Gatsby.js) & hosted on Netlify.</p>
          </div>
        </footer>
      
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
